<template>
  <div class="butch-update">
    <div class="left">
      <fm-title title-text="选择员工" :note-text="'总数:' + count + ' 已选:' + chooseWorkers.length"></fm-title>
      <div class="worker-table">
        <fm-table-new
          :simple-filter="true"
          :columns="columns"
          :auto-height="true"
          :defaultOptionsFilterMethod="defaultOptionsFilterMethod"
          empty-search="__null"
          border="row"
          :handler-counter="countFun"
          @counterChange="countDataChange"
          :data-list="showWorkerList"
          :selection="true"
          :stripe="false"
          size="small"
          emptyPlaceholder="-"
          @on-select-change="checkDataChange">
        </fm-table-new>
      </div>
    </div>
    <div class="right" v-loading="loading">
      <fm-title title-text="批量维护表单"></fm-title>
      <div style="margin:10px 0 10px">请选择变更字段</div>
      <fm-select style="width:100%;margin-bottom: 20px;" clearable absolute filterable v-model="updateKey">
        <fm-option v-for="item in updateKeyList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
      </fm-select>
      <worker-batch-form ref="batchForm" :updateKeys="[updateKey]"></worker-batch-form>
      <div class="form-btn">
        <fm-btn v-if="updateKey" @click="save">保存</fm-btn>
      </div>
    </div>
  </div>
</template>

<script>
import WorkerBatchForm from './workerBatchForm'
import {
  workerRequest
} from '@/api'

export default {
  components: {
    WorkerBatchForm
  },
  props: {
    workerList: {
      type: Array
    }
  },
  data () {
    return {
      count: 0,
      loading: false,
      chooseWorkers: [],
      updateKey: null
    }
  },
  created () {
    this.$store.dispatch('loadPositionList')
  },
  computed: {
    showWorkerList () {
      return this.workerList.map(v => {
        return Object.assign({}, v, {
          positionIdText: v.positionData ? v.positionData.name : null,
          positionIdsText: v.positionDatas && v.positionDatas.length > 0 ? v.positionDatas.map(a => a.name).join(',') : null,
        })
      })
    },
    updateKeyList () {
      let data = this.columns.map(v => {
        return {
          key: v.field === 'positionIdsText' ? 'positionIds' : (v.field === 'positionIdText' ? 'positionId' : v.field),
          label: v.title
        }
      })
      return data.filter(v => !['code', 'name', 'age', 'idNo', 'maritalStatus', 'phone'].includes(v.key))
    },
    columns () {
      return [
        { title: '工号', field: 'code', sort: true, width: 120 },
        { title: '姓名', field: 'name', sort: true, width: 120},
        { title: '性别', field: 'sex', sort: true, width: 120, filters: [{
          label: '男',
          value: '男'
        },
        {
          label: '女',
          value: '女'
        }]
        },
        { title: '民族', width: 120, field: 'clan', sort: true, filters: this.$store.getters.selectDatas['worker_clan_list'].map(v => {
          return {
            label: v.label,
            value: v.key
          }
        })},
        {
          title: '所属职务',
          field: 'positionIdText',
          width: 120,
          sort: true, filters: this.$store.getters.positionList.map(v => {
          return {
            label: v.label,
            value: v.label
          }
        })
        },
        { title: '兼职职务', width: 120, field: 'positionIdsText', sort: true, filters: this.$store.getters.positionList.map(v => {
          return {
            label: v.label,
            value: v.label
          }
        }),
        filterMethod (parm) {
          if (parm.value === '__null') {
            return [undefined, '', null].includes(parm.row.positionIdsText)
          }
          if (parm.row.positionIdsText) {
            let l = parm.row.positionIdsText.split(',')
            return l.includes(parm.value)
          } else {
            return false
          }
        }
        },
        {
          title: '出生年月', field: 'birth', width: 250, sort: true, dataType: Date, filterRange: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.birth ? rowData.birth.slice(0, 10) : '-')
          }
        },
        {
          title: '年龄', field: 'age', sort: true, dataType: Number, width: 120, filterRange: true
        },
        { title: '政治面貌', width: 120,field: 'politicalStatus', sort: true,
        filters: this.$store.getters.selectDatas['worker_political_status_list'].map(v => {
          return {
            label: v.label,
            value: v.key
          }
        })},
        { title: '身份证号码', field: 'idNo', sort: true, width: 184 },
        { title: '是否在编', width: 120,field: 'type', sort: true, filters: ['在编', '非在编'].map(v => {
          return {
            label: v,
            value: v
          }
        })},
        { title: '在岗状态',width: 120, field: 'jobTypeC', sort: true, filters: this.$store.getters.selectDatas['worker_zg_status'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '工龄', field: 'maritalStatus', sort: true, dataType: Number, width: 120, filterRange: true},
        { title: '是否港澳台及外籍人士',width: 120, field: 'graduatedSchool', sort: true, filters: [{
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }]},
        {
          title: '参加工作时间', field: 'correctionDate', sort: true, dataType: Date, filterRange: true,
          width: 250,
          render: (h, rowData) => {
            return h('div', rowData && rowData.correctionDate ? rowData.correctionDate.slice(0, 10) : '-')
          }
        },
        {
          title: '进入本单位时间', field: 'entryDate', sort: true, dataType: Date, filterRange: true,
          width: 250,
          render: (h, rowData) => {
            return h('div', rowData && rowData.entryDate ? rowData.entryDate.slice(0, 10) : '-')
          }
        },
        { title: '人员类别',width: 120, field: 'jobType', sort: true, filters: this.$store.getters.selectDatas['worker_job_type'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '是否特殊人才',width: 120, field: 'agreementCode', sort: true, filters: [{
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }]},
        { title: '入职方式',width: 120, field: 'employmentForm', sort: true, filters: this.$store.getters.selectDatas['employment_form'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '离职方式',width: 120, field: 'discipline', sort: true, filters: this.$store.getters.selectDatas['worker_resign_list'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '是否专业技术人员',width: 120, field: 'address', sort: true, filters: [{
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }]},
        { title: '专业技术人员专业名称',width: 120, field: 'shuangjiantiao', sort: true },
        { title: '专业技术人员专业类别',width: 120, field: 'email', sort: true, filters: this.$store.getters.selectDatas['zyjsryzylb'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '是否卫生专业技术人员',width: 120, field: 'jobTitle', sort: true, filters: [{
          label: '是',
          value: '是'
        },
        {
          label: '否',
          value: '否'
        }]},
        { title: '学历', field: 'education', width: 120, sort: true, filters: this.$store.getters.selectDatas['worker_education_list'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '学位', field: 'degree', width: 120, sort: true, filters: this.$store.getters.selectDatas['worker_edu_degree'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })},
        { title: '籍贯', field: 'hometown', width: 120, sort: true },
        { title: '联系电话', field: 'phone', width: 120, sort: true },
        { title: '个人身份', field: 'sf',width: 120, sort: true, filters: this.$store.getters.selectDatas['worker_identity_list'].map(v => {
          return {
            label: v.key,
            value: v.key
          }
        })}].map(v => {
        v.search = true
        return v
      })
    },
  },
  methods: {
    async save () {
      if (this.chooseWorkers.length === 0) {
        this.$notice.info({
          title: '系统提示',
          desc: '请选择员工'
        })
        return
      }
      if (!this.updateKey) {
        this.$notice.info({
          title: '系统提示',
          desc: '请选择变更字段'
        })
        return
      }
      let data = this.$refs.batchForm.getFormData()
      let updateData = {}
      let label = this.updateKeyList.find(v => v.key === this.updateKey).label
      updateData[this.updateKey] = data[this.updateKey]
      let valueText = updateData[this.updateKey]
      if (valueText === null || valueText === undefined || valueText === '') {
        updateData[this.updateKey] = null
        valueText = '空值'
      } else if (this.updateKey === 'positionId') {
        valueText = this.$store.getters.positionList.find(v => v.key === valueText).label
      } else if (this.updateKey === 'positionIds') {
        let positionIds = valueText ? valueText.map(v => Number(v)) : []
        updateData[this.updateKey] = positionIds.join(',')
        if (positionIds.length === 0) {
          valueText = '空值'
        } else {
          valueText = this.$store.getters.positionList.filter(v => positionIds.includes(v.key)).map(v => v.label).join(',')
        }
      }
      let noteText = '确定将' + this.chooseWorkers.length + '人的 “' + label + '”变更为:“' + valueText + '”吗? </br>所选员工：</br>' + (this.chooseWorkers.map(v => v.name).join('，'))
      let c = await this.$dialog.confirm({title: '批量维护确认', content: noteText})
      if (!c) {
        return
      }
      if (updateData[this.updateKey] === null || updateData[this.updateKey] === undefined || updateData[this.updateKey] === '') {
        let c1 = await this.$dialog.confirm({title: '系统提示', content: '未选择或输入“' + label + '”，确定继续修改么？保存后将设为' + valueText + '!' })
        if (!c1) {
          return
        }
      }
      this.loading = true
      await workerRequest.batchSave({
        ids: this.chooseWorkers.map(v => v.id).join(','),
        data: updateData
      })
      this.$notice.success({
        title: '系统提示',
        desc: '批量维护完成'
      })
      this.$store.dispatch('loadWorkerList', true)
      this.loading = false
    },
    defaultOptionsFilterMethod (filterValue, columnValue) {
      return filterValue === columnValue
    },
    countDataChange (data) {
      this.count = data
    },
    countFun (data) {
      return data.length
    },
    checkDataChange (data) {
      this.chooseWorkers = data
    }
  }
}
</script>

<style scoped lang="less">
.butch-update {
  width: 100%;
  height: 80vh;
  display: flex;
  .left {
    flex: 1;
    height: 100%;
    .worker-table {
      width: 100%;
      height: calc(100% - 48px);
    }
  }
  .right {
    width: 300px;
    margin-left: 20px;
    height: 100%;
  }
  .form-btn {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
