import { render, staticRenderFns } from "./batchUpdate.vue?vue&type=template&id=838b62ae&scoped=true&"
import script from "./batchUpdate.vue?vue&type=script&lang=js&"
export * from "./batchUpdate.vue?vue&type=script&lang=js&"
import style0 from "./batchUpdate.vue?vue&type=style&index=0&id=838b62ae&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "838b62ae",
  null
  
)

export default component.exports